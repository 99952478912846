@import "./../../vars";

.schedule-container {
  height: 200px;
  overflow: auto;
  padding: map-get($marPad, normal);
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

@media (min-width: map-get($breakpoints, phone)) {
  .schedule-container {
    height: 300px;
  }
}

@media (min-width: map-get($breakpoints, tablet)) {
  .schedule-container {
    height: 400px;
  }
}

@media (min-width: map-get($breakpoints, laptop)) {
  .schedule-container {
    height: 500px;
  }
}