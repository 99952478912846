@import "./../../vars";

.home {
  padding: map-get($marPad, normal);

  .home-header {
    background-color: map-get($colors, secondary);
    padding: map-get($marPad, xxxLarge);
    text-align: center;
    margin-bottom: map-get($marPad, large);

    h1 {
      font-size: map-get($textSizes, xxLarge);
    }

    p {
      font-size: map-get($textSizes, large);
      color: map-get($colors, slate);
    }
  }

  .home-main {
    .home-cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: map-get($marPad, normal);

      .card {
        background-color: map-get($colors, snow);
        padding: map-get($marPad, normal);
        box-shadow: map-get($boxShadow, small);
        border-radius: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &.calendar {
          grid-column: span 1;
          grid-row: span 2;
          min-height: 600px;
        }

        &.announcements,
        &.assignments {
          grid-column: span 1;
        }

        &.class-schedule {
          grid-column: span 1;
          grid-row: span 2;
        }

        h2 {
          font-size: map-get($textSizes, large);
          margin-bottom: map-get($marPad, small);
        }

        .scrollable-content {
          flex-grow: 1;
          overflow-y: auto;
        }

        iframe {
          width: 100%;
          height: 100%;
          flex-grow: 1; /* Ensure the iframe takes up the remaining space */
        }
      }
    }
  }
}

@media (max-width: map-get($breakpoints, laptop)) {
  .home {
    .home-main {
      .home-cards {
        grid-template-columns: 1fr;

        .card {
          grid-column: span 1;
          grid-row: auto;
        }
      }
    }
  }
}
